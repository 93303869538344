export function getQueryString(name) {
  var r = ""

  try {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    if (window.location.search) {
      let b = window.location.search.split('?')
      r = b[1].match(reg)
    } else {
      var url = window.location.href.split('?')
      r = url[1].match(reg)
    }
    if (r != null) return unescape(r[2])
    return null
  } catch (error) {
    return null
  }
}

export function downloadApp(info, showTip = false) {
  showTip && ShowMsg('正在下载中<br/>若无法下载，请尝试用系统自带浏览器打开', 3)
  const downUrl = info.downloadInfo['shopDownloadUrl']
  console.log(downUrl)
  if (String(info.downloadInfo['installType']) !== '4') {
    // window.liquidInstance && window.liquidInstance.wakeupOrInstall()
    try {
      // window.location = 'plus.saas-xy.com://'
      window.location = `${info.downloadInfo.liquidLinkerAppKey}://`
    } catch (error) {
      console.log(info.downloadInfo.liquidLinkerAppKey, error)
    }
    if (window.deviceInfo.isiOS) {
      setTimeout(() => {
        window.location = downUrl
      }, 2000)
    } else {
      setTimeout(() => {
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = downUrl
        document.body.appendChild(a)
        a.click()
      }, 2000)
    }
  } else {
    window.open(downUrl, '_blank')
  }

  // const option = {
  //   scheme: {
  //     protocol: 'ykshortvideo',
  //   },
  //   intent: {
  //     package: info.downloadInfo.packageName,
  //     scheme: 'ykshortvideo',
  //   },

  //   universal: {
  //     host: 'applinks:plus.saas-xy.com',
  //   },
  //   appstore: 'https://itunes.apple.com/cn/app/id1383186862',
  //   yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.youku.shortvideo',
  //   fallback: downUrl,
  //   timeout: 2000,
  //   logFunc: (status) =>{
  //     if(status === 'failure'){
  //       if(window.deviceInfo.isiOS){
  //         window.location = downUrl;
  //       }else{
  //         var a = document.createElement("a");
  //         a.style.display = "none";
  //         a.href = downUrl;
  //         document.body.appendChild(a);
  //         a.click();
  //       }
  //     }
  //   }

  // };
  // try {
  //   const calllib = new CallApp(option);
  //   calllib.open({ path: '' });
  // } catch (error) {
  //   console.log(error)
  //   if(window.deviceInfo.isiOS){
  //     window.location = downUrl;
  //   }else{
  //     var a = document.createElement("a");
  //     a.style.display = "none";
  //     a.href = downUrl;
  //     document.body.appendChild(a);
  //     a.click();
  //   }
  // }

  if (window.deviceInfo.isiOS && String(info.downloadInfo['installType']) === '4' && info.majiaCode) {
    downloadCount(info.majiaCode)
  }
}

export function ShowMsg(content, time = 2) {
  window.layer.open({
    content: content,
    time: time,
    skin: 'msg'
  })
}

export function isValidBrowser() {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger\/[0-9]/i)) {
    // return 'weixin'
    return false
  }

  if (ua.match(/WeiBo/i) == 'weibo') {
    //在新浪微博客户端打开
    // $('.from').html("微博");
    return false
  }

  if (ua.match(/QQ\/[0-9]/i)) {
    // return 'QQ'
    return false
  }
  return true
}

export function downloadCount(code) {
  let url = `/api/open/applet/landing/majiaDownloadCount?code=${code}`
  var timestamp = new Date().getTime()
  window.$.ajax({
    type: 'get',
    // data: JSON.stringify({ data: { packageCode: deviceInfo.packageCode } }),
    // dataType: 'json',
    // contentType: 'application/json;charset=utf-8',
    headers: {
      Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
      'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
      'X-Client-TimeStamp': timestamp
    },
    url,
    success: function (data) {}
  })
}
export function addLiquidId(data, callback = () => {}) {
  window.$.ajax({
    type: 'post',
    url: `/api/open/user_c/inviteRelation/add`,
    data: JSON.stringify({ data }),
    // dataType: 'json',
    contentType: 'application/json;charset=utf-8',
    headers: {
      Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
      'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
      'X-Client-TimeStamp': new Date().getTime()
    },
    success: function (res) {
      console.log('addLiquidId==', res)
      if (res.data) {
        localStorage.setItem('beInvitedUuid', res.data.beInvitedUuid)
        // 执行回调（配置图片域名数据）
        res.data.appId && callback(res.data.appId)
      }
    }
  })
}

// 资源域名配置
export function configResourceDomain(data) {
  var timestamp = new Date().getTime()
  window.$.ajax({
    type: 'post',
    url: '/api/open/applet/domain/list',
    data: JSON.stringify({ data }),
    contentType: 'application/json;charset=utf-8',
    headers: {
      Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
      'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
      'X-Client-TimeStamp': timestamp
    },
    success: function (res) {
      if (res && res.code === '0000') {
        window.localStorage.setItem('fileDomainConfig', JSON.stringify(res.data))
      }
    }
  })
}

export function getIP(callback) {
  window.$.ajax({
    type: 'get',
    url: 'https://ipv4.icanhazip.com/',
    success: function (data) {
      console.log(data)
      callback(data.replace(/\r|\n/gi, ''))
    },
    error: function (err) {
      callback('')
    }
  })
}

export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16).toUpperCase()
  })
}

export function lightPackege(imgSrc, url, appName, appId, foreign = false) {
  var base64Str = imgSrc.replace(/^data:image\/\w+;base64,/, '')
  var iconXML = base64Str?.length ? `<key>Icon</key><data>${base64Str}</data>` : ''
  // URL是必填的
  var URL = `<key>URL</key><string>${url}</string>`
  var xmlText = `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<plist version="1.0">
<dict>
	<key>PayloadContent</key>
	<array>
		<dict>
			<key>FullScreen</key>
			<true/>
			${iconXML}
			<key>IgnoreManifestScope</key>
			<true/>
			<key>IsRemovable</key>
			<${true}/>
			<key>Label</key>
			<string>${appName}${foreign ? '-海外版' : ''}</string>
			<key>PayloadDescription</key>
			<string>${appName}${foreign ? '-海外版' : ''}WebClip配置</string>
			<key>PayloadDisplayName</key>
			<string>Web Clip</string>
			<key>PayloadIdentifier</key>
			<string>com.apple.webClip.managed.${appId}</string>
			<key>PayloadType</key>
			<string>com.apple.webClip.managed</string>
			<key>PayloadUUID</key>
			<string>${appId}</string>
			<key>PayloadVersion</key>
			<integer>1</integer>
			<key>Precomposed</key>
			<true/>
			${URL}
		</dict>
	</array>
	<key>PayloadDisplayName</key>
	<string>${appName}${foreign ? '-海外版' : ''}</string>
	<key>PayloadIdentifier</key>
	<string>CoderWGBDeMacBook-Pro.${appId}${foreign ? '-1' : ''}</string>
	<key>PayloadRemovalDisallowed</key>
	<false/>
	<key>PayloadType</key>
	<string>Configuration</string>
	<key>PayloadUUID</key>
	<string>${getUUID()}</string>
	<key>PayloadVersion</key>
	<integer>1</integer>
</dict>
</plist>`

  console.log(url, appName)
  var base64 = 'data:application/x-apple-aspen-config;base64,' + btoa(unescape(encodeURIComponent(xmlText)))
  window.location.href = base64
  window.setTimeout(function () {
    window.location.href = 'shortcuts://x-callback-url/run-shortcut?x-error=App-prefs:root=General&path=ManagedConfigurationList/PurgatoryInstallRequested'
  }, 3000)
}
// export function lightPackege(imgSrc, url, appName, appId, foreign = false) {
 
// }
