<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import mobileDetect from 'mobile-detect'
import { getQueryString } from '@/utils/common'
import { isValidBrowser, addLiquidId, getIP } from '@/utils/download'
export default {
  name: 'App',
  data() {
    return {}
  },
  created() {
    // this.innitChannelInfo()
  },
  watch: {},
  methods: {
    innitChannelInfo() {
      const md = new mobileDetect(u)
      let ver = ''
      if (md.os() == 'iOS') {
        //ios系统的处理
        ver = md.versionStr('iPhone')
      } else if (md.os() == 'AndroidOS') {
        //Android系统的处理
        ver = md.versionStr('Android')
      }
      var u = navigator.userAgent
      let deviceInfo = {}
      deviceInfo.isAndroid = !!(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) //android终端
      deviceInfo.isiOS = !!u.match(/(iPhone|iPod|ios|iPad)/i) //ios终端
      deviceInfo.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
      deviceInfo.isMobile = deviceInfo.isAndroid || deviceInfo.isiOS
      deviceInfo.isValidBrowser = isValidBrowser()
      let parseStr = ['', '', '', '']
      if (getQueryString('a')) {
        let encStr = atob(getQueryString('a'))
        // const re = /##(.*)##(.*)##/
        const re = /##(.*)##(.*)##(.*)##/
        // let encStr = '##xvuh56##111fwl##111##'
        parseStr = encStr.match(re)
      }
      deviceInfo.packageCode = parseStr[1] || process.env.VUE_APP_CHANNEL_CODE
      deviceInfo.invitationCode = parseStr[2] || ''
      deviceInfo.appId = parseStr[3] || process.env.VUE_APP_APP_ID
      window.deviceInfo = deviceInfo
      let that = this
      let code = ''
      if (window.location.hash.split('?')[1]) {
        let tempCode = window.location.hash.split('?')[1].split('=')
        console.log(tempCode, 'tempCode')
        if(tempCode.length>1){
          code = tempCode[1]
        }
      }
      console.log(that.$router.currentRoute, 'that.$router', window.location.hash.split('?')[1])
      getIP(function (ip, area) {
        const params = {
          appId: deviceInfo.appId,
          channelCode: code || deviceInfo.packageCode,
          invitationCode: deviceInfo.invitationCode,
          deviceInfo: {
            ip: ip || '',
            // localIp: ,
            osType: md.os() == 'iOS' ? 1 : 0,
            osVersion: ver.replace(/\_/g, '.'),
            deviceWidth: window.screen.width,
            deviceHeight: window.screen.height,
            deviceRatio: window.devicePixelRatio
          },
          systemType: 1
        }
        addLiquidId(params)
        // sessionReport({ ip })
      })
    }
  }
}
</script>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
